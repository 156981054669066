// example query path
// http://localhost:3000/search?agentId=asdo&agencyId=asdo&areaId=asdo&priceMin=asdo&priceMax=asdo&houseSizeMin=asdo&houseSizeMax=asdo&plotSizeMin=asdo&plotSizeMax=asdo&roomCountMin=asdo&transactionalType=asdo&propertyType=asdo&isNewBuild=asdo&hasPool=asdo&isPetFriendly=asdo&hasGarden=asdo&hasFlatlet=asdo&isSecurityEstate=asdo&isRetiermentVillage=asdo&isOnAuction=asdo// ?agentId=asdo&agencyId=asdo&areaId=asdo&priceMin=asdo&priceMax=asdo&houseSizeMin=asdo&houseSizeMax=asdo&plotSizeMin=asdo&plotSizeMax=asdo&roomCountMin=asdo&transactionalType=asdo&propertyType=asdo&isNewBuild=asdo&hasPool=asdo&isPetFriendly=asdo&hasGarden=asdo&hasFlatlet=asdo&isSecurityEstate=asdo&isRetiermentVillage=asdo&isOnAuction=asdo

import { PropertyType, propertyTypeFromString } from "./propertyType";
import { TransactionType, transactionTypeFromString } from "./transactionType";

class SearchListingsQuery {
    agentId!: String;
    agencyId!: String;
    areaId!: String;
    priceMin!: String;
    priceMax!: String;
    houseSizeMin!: String;
    houseSizeMax!: String;
    plotSizeMin!: String;
    plotSizeMax!: String;
    roomCountMin!: String;
    transactionalType!: TransactionType; // Rent or Buy
    propertyType!: Array<PropertyType>; // House, Flat, Vacant Land,... and or Comercial
    isNewBuild!: boolean;
    hasPool!: boolean;
    isPetFriendly!: boolean;
    hasGarden!: boolean;
    hasFlatlet!: boolean;
    isSecurityEstate!: boolean;
    isRetiermentVillage!: boolean;
    isOnAuction!: boolean;
    currentPage: number = 0;

    private static stringToPropertyTypeArray(str: string | null): Array<PropertyType> {
        if (str === null) {
            return [PropertyType.Any]
        }
        var types = str.split(",").map((s) => propertyTypeFromString(s));
        if (PropertyType.Any in types) {
            return [PropertyType.Any]
        }
        return types;
    }

    private static stringToBool(str: string | null, def: boolean): boolean {
        if (str === null) {
            return def;
        }
        if (str === "true") {
            return true;
        }
        return false;
    }

    public static transformFromQueryParams(query: URLSearchParams): SearchListingsQuery {
        var searchQuery = new SearchListingsQuery();
        searchQuery.agentId = query.get("agentId") ?? "";
        searchQuery.agencyId = query.get("agencyId") ?? "";
        searchQuery.areaId = query.get("areaId") ?? "";
        searchQuery.priceMin = query.get("priceMin") ?? "";
        searchQuery.priceMax = query.get("priceMax") ?? "";
        searchQuery.houseSizeMin = query.get("houseSizeMin") ?? "";
        searchQuery.houseSizeMax = query.get("houseSizeMax") ?? "";
        searchQuery.plotSizeMin = query.get("plotSizeMin") ?? "";
        searchQuery.plotSizeMax = query.get("plotSizeMax") ?? "";
        searchQuery.roomCountMin = query.get("roomCountMin") ?? "";
        searchQuery.transactionalType = transactionTypeFromString(query.get("transactionalType") ?? "");
        searchQuery.propertyType = this.stringToPropertyTypeArray(query.get("propertyType"));
        searchQuery.isNewBuild = this.stringToBool(query.get("isNewBuild"), false);
        searchQuery.hasPool = this.stringToBool(query.get("hasPool"), false);
        searchQuery.isPetFriendly = this.stringToBool(query.get("isPetFriendly"), false);
        searchQuery.hasGarden = this.stringToBool(query.get("hasGarden"), false);
        searchQuery.hasFlatlet = this.stringToBool(query.get("hasFlatlet"), false);
        searchQuery.isSecurityEstate = this.stringToBool(query.get("isSecurityEstate"), false);
        searchQuery.isRetiermentVillage = this.stringToBool(query.get("isRetiermentVillage"), false);
        searchQuery.isOnAuction = this.stringToBool(query.get("isOnAuction"), false);
    return searchQuery;
    }
}

export { SearchListingsQuery };