import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, createTheme } from '@mui/material';
import { FullProperty } from '../../models/fullProperty';
import "./index.css"
import { StringsService } from '../../services/strings/strings';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import KingBedIcon from '@mui/icons-material/KingBed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { ThemeProvider } from '@emotion/react';
import { useScreenSize } from '../../hooks/useScreenSize';
import { TransactionType } from '../../models/transactionType';
import { AreaService } from '../../services/area/areaService';

interface IProps {
    listing: FullProperty
}

function DesktopCard({ listing }: IProps) {
    return (
        <div className='ListingCardBase'>
            <Card sx={{ backgroundColor: "#F0FFFF", display: 'flex', maxHeight: "230px", width: "100%" }} >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent>
                        <div className="flex-row jcsb">
                            <div className="flex-column">
                                <Typography variant="h5" component="div">
                                    {StringsService.getStringById(listing.id + "-property-price-zar-displayable")}
                                </Typography>
                                <Typography variant="subtitle1" component="div">
                                    {StringsService.getStringById(listing.id + "-short-property-summary")}
                                </Typography>
                                <Typography variant="subtitle2" component="div">
                                    {StringsService.propertyTypeAsString(listing)} for {StringsService.transactionTypeAsString(listing)} in {AreaService.getNameByArea(listing.area)}
                                </Typography>
                            </div>
                        </div>
                        < div className="p8" />
                        <div className="flex-row ">
                            <div className='pr-16'>
                                <KingBedIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.bedroomCount}
                            </div>
                            <div className='pr-16'>
                                <BathtubIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.bathroomCount}
                            </div>
                            <div className='pr-16'>
                                <DirectionsCarIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.carCount}
                            </div>
                            <div className='pr-16'>
                                <SquareFootIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.houseSize}
                                {" "}
                                {StringsService.getStringById("meters_squared")}
                            </div>
                        </div>

                    </CardContent>
                </Box>
            </Card>
        </div>
    );
}

function ListingSummaryCard({ listing }: IProps) {
    return < DesktopCard listing={listing} />;

}

export { ListingSummaryCard };