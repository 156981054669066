import React, { useState } from 'react';
import "./index.css"
import Card from '@mui/material/Card';
import { CardMedia, Typography } from '@mui/material';
import { StringsService } from '../../services/strings/strings';
import Button from '@mui/material/Button';


interface IProps {
  agentId: string | String
}


function AgentCard(props: IProps) {
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);

  return (
    <Card sx={{ backgroundColor: "#F0FFFF" }} >
      <div className='jcc p8'>
        <CardMedia
          component="img"
          sx={{ width: 151 }}
          image={StringsService.getStringById("agent-" + props.agentId + "-agent-image")}
          alt="Live from space album cover"
        />
      </div>
      <div className='jcc p8'>
        <Typography variant="subtitle2">
          {StringsService.getStringById("agent-" + props.agentId + "-name")}
        </Typography>
      </div>
      <div className='jcc p8'>
        {
          showPhoneNumber ?
            <Typography variant="subtitle2">
              {StringsService.getStringById("agent-" + props.agentId + "-contact-number")}
            </Typography>
            :
            <Button variant="contained" sx={{ backgroundColor: "teal" }}
            onClick={() => {setShowPhoneNumber(true)}}
            >
              Show Phone Number
            </Button>
        }
      </div>
    </Card>
  );
}

export default AgentCard;
