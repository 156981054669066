enum TransactionType {
    Rent = "rent",
    Buy = "buy",
    Both = "both"
}

const transactionTypeFromString = (str: string) => {
    switch(str){
        case "rent":
            return TransactionType.Rent;
        case "buy":
            return TransactionType.Buy
        case "both":
        default:
            return TransactionType.Both;

    } 
}

export { TransactionType, transactionTypeFromString };