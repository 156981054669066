import React from "react";
import {
  useLocation,
  useParams
} from "react-router-dom";
import "./index.css"
import { Header } from "../header";
import ImageGalleryCard from "./imageGalleryCard";
import AgencyCard from "./agencyCard";
import { FullProperty } from "../models/fullProperty";
import { SearchService } from "../services/search/search";
import { useScreenSize } from "../hooks/useScreenSize";
import AgentCard from "./agentCard";
import ShareCard from "./shareCard";
import { ListingSummaryCard } from "./summaryCard";
import DescriptionCard from "./longDescriptionCard";
import OverviewCard from "./overviewCard";
import { Helmet } from "react-helmet";
import { StringsService } from "../services/strings/strings";
import { AreaService } from "../services/area/areaService";
import ReactGA from "react-ga4";

interface IBodyProps {
  property: FullProperty
}

function DesktopListingBody(props: IBodyProps) {
  return (<div className="row jcsb">
    {/* main column */}
    <div className="col w100p">
      <ListingSummaryCard listing={props.property} />
      <div className="p8" />
      <DescriptionCard id={props.property.id} />
      <div className="p8" />
      <OverviewCard listing={props.property} />
    </div>
    <div className="p8" />
    {/* side column */}
    <div className="col minW250">
      <AgencyCard agencyId={props.property.agencyId} />
      <div className="p8" />
      <AgentCard agentId={props.property.agentId} />
      <div className="p8" />
      <ShareCard id={props.property.id} />
    </div>
  </div>)
}

function MobileCard(props: IBodyProps) {
  return (
    <div className="col w100p">
      <ListingSummaryCard listing={props.property} />
      <div className="p8" />
      <DescriptionCard id={props.property.id} />
      <div className="p8" />
      <OverviewCard listing={props.property} />
      <div className="p8" />
      <AgencyCard agencyId={props.property.agencyId} />
      <div className="p8" />
      <AgentCard agentId={props.property.agentId} />
      <div className="p8" />
      <ShareCard id={props.property.id} />
    </div>
  )
}

function ListingBody(props: IBodyProps) {
  var screenSize = useScreenSize()
  if (screenSize.width > 800) {
    return <DesktopListingBody property={props.property} />
  }
   return  <MobileCard property={props.property} />
}

function ListingDetailsPage() {
  const { id } = useParams();
  ReactGA.send({ hitType: "pageview", page: "/listing/" + id, title: "Casa Grab" });
  const listing = SearchService.getById(id ?? "") ?? new FullProperty();
  return (
    <div >
      <Helmet>
        <title>Casa Grab - {StringsService.getStringById(id + "-short-property-summary")}</title>
        <meta name="description" content={"Casa Grab Properties " +
          StringsService.propertyTypeAsString(listing) + " for " + StringsService.transactionTypeAsString(listing) + " in " + AreaService.getNameByArea(listing.area) + 
          ". " + StringsService.getStringById(id + "-description-header")} />
        {/* <!-- Open Graph Tags --> */}
        <meta property="og:title" content="Casa Grab - Real Estate Listings" />
        <meta property="og:description" content={StringsService.getStringById(id + "-description-header")} />
        <meta property="og:image" content={StringsService.getStringById(id + "-thumbnail")} />

        {/* <!-- Twitter Card Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Casa Grab - Real Estate Listings" />
        <meta name="twitter:description" content={StringsService.getStringById(id + "-description-header")} />
        <meta name="twitter:image" content={StringsService.getStringById(id + "-thumbnail")} />

      </Helmet>
      <Header />
      <div className="p8" />
      <div className="ListingDetailPage">
        <ImageGalleryCard id={id ?? ""} />
        <div className="p8" />
        <ListingBody property={listing} />
      </div>
      <div className="p8" />
    </div>
  );
}

export default ListingDetailsPage;
