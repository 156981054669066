
import React from "react";
import { useLocation } from "react-router-dom";
import "./index.css"

// A custom hook that builds on useLocation to parse
// the query string for you.
function usePathname() {
    const { pathname } = useLocation();
    console.log(pathname);
    return React.useMemo(() => pathname, [pathname]);
}

interface INavButtonProps {
    href: string,
    text: string,
}

function NavButton(props: INavButtonProps) {
    return (
        <a href={props.href}
            className="button cc-jumbo-white cc-jumbo-button w-inline-block">
            <div>{props.text}</div>
        </a>)
}

function OneClickNavigation() {
    let pathname = usePathname();
    if (pathname.toString() === "/") {
        return <NavButton
            href="/search?agentId=asdo&agencyId=asdo&areaId=asdo&priceMin=asdo&priceMax=asdo&houseSizeMin=asdo&houseSizeMax=asdo&plotSizeMin=asdo&plotSizeMax=asdo&roomCountMin=asdo&transactionalType=asdo&propertyType=asdo&isNewBuild=asdo&hasPool=asdo&isPetFriendly=asdo&hasGarden=asdo&hasFlatlet=asdo&isSecurityEstate=asdo&isRetiermentVillage=asdo&isOnAuction=asdo// ?agentId=asdo&agencyId=asdo&areaId=asdo&priceMin=asdo&priceMax=asdo&houseSizeMin=asdo&houseSizeMax=asdo&plotSizeMin=asdo&plotSizeMax=asdo&roomCountMin=asdo&transactionalType=asdo&propertyType=asdo&isNewBuild=asdo&hasPool=asdo&isPetFriendly=asdo&hasGarden=asdo&hasFlatlet=asdo&isSecurityEstate=asdo&isRetiermentVillage=asdo&isOnAuction=asdo"
            text="View Properties"
        />
    }
    if (pathname.toString().includes("/search")) {
        return <NavButton
            href="/"
            text="Home"
        />
    }
    if (pathname.toString().includes("/listing")) {
        return <NavButton
            href="/search?agentId=asdo&agencyId=asdo&areaId=asdo&priceMin=asdo&priceMax=asdo&houseSizeMin=asdo&houseSizeMax=asdo&plotSizeMin=asdo&plotSizeMax=asdo&roomCountMin=asdo&transactionalType=asdo&propertyType=asdo&isNewBuild=asdo&hasPool=asdo&isPetFriendly=asdo&hasGarden=asdo&hasFlatlet=asdo&isSecurityEstate=asdo&isRetiermentVillage=asdo&isOnAuction=asdo// ?agentId=asdo&agencyId=asdo&areaId=asdo&priceMin=asdo&priceMax=asdo&houseSizeMin=asdo&houseSizeMax=asdo&plotSizeMin=asdo&plotSizeMax=asdo&roomCountMin=asdo&transactionalType=asdo&propertyType=asdo&isNewBuild=asdo&hasPool=asdo&isPetFriendly=asdo&hasGarden=asdo&hasFlatlet=asdo&isSecurityEstate=asdo&isRetiermentVillage=asdo&isOnAuction=asdo"
            text="View Properties"
        />
    }
    return <div />

}

function Header() {
    return <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease"
        role="banner" className="navigation w-nav">
        <div className="navigation-wrap">
            <a aria-current="page" href="/"
                className="logo-link w-nav-brand w--current" aria-label="home"><img
                    src="./src_assets/659bf227c489a49f48e619d0_Test.png"
                    alt="Casa Grab Logo" loading="lazy"
                    width="175" sizes="(max-width: 479px) 100vw, 175px"
                    srcSet="https://assets-global.website-files.com/6577436afb53b22b5c1e91ce/659bf227c489a49f48e619d0_Test-p-500.png 500w, https://assets-global.website-files.com/6577436afb53b22b5c1e91ce/659bf227c489a49f48e619d0_Test-p-800.png 800w, https://assets-global.website-files.com/6577436afb53b22b5c1e91ce/659bf227c489a49f48e619d0_Test.png 1000w" />
            </a>
            <OneClickNavigation />
        </div>
        <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
    </div>
}

export { Header, OneClickNavigation };