import { Area } from "../../models/area";
import raw from "./areas.json"

const getNameByArea = (area: Area): string => {
    var higherName = "";
    var areas = new Map(Object.entries(raw));
    if (area.provinceId === "") {
        return ""
    }
    var selectedArea = new Map(Object.entries(areas.get(area.provinceId.toString()) as any ?? {}))
    if (selectedArea === undefined) { return ""; }
    if (area.areadId === "" || (new Map(Object.entries(selectedArea.get("children") as any)).get(area.areadId.toString()) === undefined)) {
        return ((selectedArea.get("name") as string)  ?? "")
    }
    higherName = (selectedArea.get("name") as string)  ?? "";
    areas = new Map(Object.entries(selectedArea.get("children") as any))
    selectedArea = new Map(Object.entries(areas.get(area.areadId.toString()) as any))

    if (area.suburbId === "" || new Map(Object.entries(selectedArea.get("children") as any)).get(area.suburbId.toString()) === undefined) {
        return ((selectedArea.get("name") as string)  ?? "") + ", " + higherName
    }
    higherName = (selectedArea.get("name") as string)  ?? "";
    areas = new Map(Object.entries(selectedArea.get("children") as any))
    selectedArea = new Map(Object.entries(areas.get(area.suburbId.toString()) as any))
    return ((selectedArea.get("name") as string)  ?? "") + ", " + higherName
}

const AreaService = {
    getNameByArea: getNameByArea
}

export { AreaService };