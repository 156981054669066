enum PropertyType {
    House = "house",
    Flat = "flat",
    VacantLand = "vacant",
    Comercial = "commercial",
    Townhouse = "townhouse",
    Farm = "farm",
    Any = "any"
}

const propertyTypeFromString = (str: string) => {
    switch (str) {
        case "house":
            return PropertyType.House;
        case "flat":
            return PropertyType.Flat;
        case "vacant":
            return PropertyType.VacantLand;
        case "commercial":
            return PropertyType.Comercial;
        case "townhouse":
            return PropertyType.Townhouse;
        case "farm":
            return PropertyType.Farm;
        default:
            return PropertyType.Any;

    }
}

export { PropertyType, propertyTypeFromString };