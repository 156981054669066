import React, { useState } from 'react';
import "./index.css"
import Card from '@mui/material/Card';
import { CardMedia, Typography } from '@mui/material';
import { StringsService } from '../../services/strings/strings';


interface IProps {
  agencyId: string | String
}


function AgencyCard(props: IProps) {

  return (
    <Card sx={{ backgroundColor: "#F0FFFF", justifyContent: "center", display: "flex !important", flexDirection: "column" }}>
      <div className='jcc p8'>
        <CardMedia
          component="img"
          image={StringsService.getStringById("agency-" + props.agencyId + "-logo")}
          alt="Live from space album cover"
        />
      </div>
      <div className='jcc p8'>
        <Typography variant="subtitle2">
          {StringsService.getStringById("agency-" + props.agencyId + "-name")}
        </Typography>
      </div>
    </Card>
  );
}

export default AgencyCard;
