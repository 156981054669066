import './index.css';
import { Helmet } from "react-helmet";
import { Header, OneClickNavigation } from "../header";
import ReactGA from "react-ga4";


function LandingPage() {
  ReactGA.send({ hitType: "pageview", page: "/", title: "Casa Grab" });

  return (
    <div>
      <Helmet >
        <title>Casa Grab - Real Estate Marketplace</title>
        <meta name="description" content="Casa Grab: Your platform to easily showcase your property listings in South Africa for free. Share details, images, and attract potential buyers. Join now and make your property stand out on day 1!" />
        {/* <!-- Open Graph Tags --> */}
        <meta property="og:title" content="Casa Grab - Real Estate Listings" />
        <meta property="og:description" content="Your platform to easily showcase your property listings in South Africa for free!" />
        <meta property="og:image" content="./src_assets/659bf227c489a49f48e619d0_Test.png" />

        {/* <!-- Twitter Card Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Casa Grab - Real Estate Listings" />
        <meta name="twitter:description" content="Your platform to easily showcase your property listings in South Africa for free.!" />
        <meta name="twitter:image" content="./src_assets/659bf227c489a49f48e619d0_Test.png" />

      </Helmet>
      <Header />
      <div className="section cc-store-home-wrap">
        <div className="intro-header">
          <div className="intro-content cc-homepage">
            <div className="intro-text">
              <div className="heading-jumbo">Casa Grab</div>
              <div className="paragraph-bigger cc-bigger-white-light">Your property deserves more than just a listing. <br />
                It deserves to make a lasting impression.<br />
              </div>
            </div>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSceNqY996GjhsCd6WNfeNBmzXLXvWNUra6e2UPthC77OGJ_FQ/viewform?usp=sf_link"
              target="_blank" className="button cc-jumbo-button cc-jumbo-white w-inline-block">
              <div>Join Our Mailing List</div>
            </a>
            <div className="p8"/>
            <OneClickNavigation />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="motto-wrap">
          <div className="label cc-light">What we believe in</div>
          <div className="heading-jumbo-small">Helping property buyers find their forever homes <br /></div>
        </div>
        <div className="divider"></div>
        <div className="home-content-wrap">
          <div className="home-section-wrap">
            <h2 className="section-heading">Who we are</h2>
            <p className="paragraph-light-fullwidth">Welcome to Casa Grab, where the essence of real estate innovation meets
              the art of finding your perfect property. As a premier online marketplace for real estate, we pride
              ourselves on connecting discerning buyers and sellers in a seamless and transformative way.<br />
              At Casa Grab, we understand that real estate is not just about transactions; it's about dreams, aspirations, and the
              profound sense of belonging that comes with finding the right space. Our platform is designed to redefine
              your real estate experience by combining cutting-edge technology with personalized service, creating a space
              where your property journey becomes a fulfilling adventure.What sets us apart is our commitment to
              excellence, transparency, and integrity. We curate a diverse portfolio of properties, ensuring that whether
              you are seeking a cozy home, a dynamic office space, or a lucrative investment opportunity, you'll find it
              within our extensive listings. Our team of dedicated professionals is passionate about guiding you through
              every step of the process, making your real estate journey not only smooth but enjoyable.Innovation is at
              the heart of everything we do. We leverage the latest advancements in technology to provide you with
              powerful tools and insights, empowering you to make informed decisions. From virtual property tours to
              detailed market analysis, we strive to bring the future of real estate to your fingertips.As advocates for
              community building, we believe in the transformative power of real estate to shape neighborhoods and enhance
              lives. By fostering connections and facilitating meaningful transactions, we contribute to the creation of
              vibrant, thriving communities.Whether you're a first-time homebuyer, a seasoned investor, or a seller
              looking to showcase your property, Casa Grab is your trusted partner in navigating the dynamic landscape of
              the real estate market. Welcome to a world where your property dreams come to life.</p>
          </div>
          <div className="w-layout-grid about-grid cc-about-2">
            <div id="w-node-_86e64837-0616-515b-4568-76c147234d41-5c1e9243">
              <div className="home-section-wrap">
                <h2 className="section-heading">Boost Your Property's Value from Day One</h2>
                <p className="paragraph-light">Be part of something special! Join our exclusive pre-launch partnership and
                  maximize your property's value right from day one. Share your details to receive insider tips, list your
                  property early, and keep up to date with our service. Together, let's make your property shine in the
                  market.</p>
              </div><a
                href="https://docs.google.com/forms/d/e/1FAIpQLSceNqY996GjhsCd6WNfeNBmzXLXvWNUra6e2UPthC77OGJ_FQ/viewform?usp=sf_link"
                target="_blank" className="button cc-jumbo-button cc-jumbo-white w-inline-block">
                <div>join</div>
              </a>
            </div><img src="./src_assets/65829dc3d777a7f9c796c21d_join-us.png"
              alt="Join Casa Grab"
              id="w-node-_86e64837-0616-515b-4568-76c147234d4c-5c1e9243"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, 922px"
              srcSet="https://assets-global.website-files.com/6577436afb53b22b5c1e91ce/65829dc3d777a7f9c796c21d_join-us-p-500.png 500w, https://assets-global.website-files.com/6577436afb53b22b5c1e91ce/65829dc3d777a7f9c796c21d_join-us-p-800.png 800w, https://assets-global.website-files.com/6577436afb53b22b5c1e91ce/65829dc3d777a7f9c796c21d_join-us.png 922w" />
          </div>
          <div className="w-layout-grid about-grid">
            <div id="w-node-e6b5ddbe-567e-8de6-4504-f403eb1b5324-5c1e9243">
              <div className="home-section-wrap">
                <h2 className="section-heading">Ready to elevate your property's visibility?</h2>
                <p className="paragraph-light">Join our exclusive pre-launch partnership and list your property seamlessly.
                  Complete the form to kickstart your journey – we'll handle the rest. Your success story starts here!</p>
              </div><a
                href="https://docs.google.com/forms/d/e/1FAIpQLSceNqY996GjhsCd6WNfeNBmzXLXvWNUra6e2UPthC77OGJ_FQ/viewform?usp=sf_link"
                target="_blank" className="button cc-jumbo-button cc-jumbo-white w-inline-block w100p">
                <div>List With us</div>
              </a>
            </div><img
              src="./src_assets/6582a76b7bfcba67f973e21a_sitting-cafe-working-laptop.jpg"
              alt="List your property from anywhere"
              loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 92vw, 740px"
              srcSet="https://assets-global.website-files.com/6577436afb53b22b5c1e91ce/6582a76b7bfcba67f973e21a_sitting-cafe-working-laptop-p-500.jpg 500w, https://assets-global.website-files.com/6577436afb53b22b5c1e91ce/6582a76b7bfcba67f973e21a_sitting-cafe-working-laptop.jpg 740w" />
          </div>
          <div className="w-layout-grid about-grid cc-about-2">
            <div id="w-node-_86e64837-0616-515b-4568-76c147234d41-5c1e9243">
              <div className="home-section-wrap">
                <h2 className="section-heading">Launching soon</h2>
                <p className="paragraph-light">
                  This platform is dedicated to properties in South Africa and will be launching soon! join our mailing list now to be among the first to experience our exclusive property listing service.
                </p>
                <p className="paragraph-light">
                  At Casa Grab, our commitment goes beyond mere listings – we're crafting an immersive experience tailored to the
                  rich tapestry of South African real estate. Our platform is poised to redefine how properties are showcased, discovered,
                  and curated.
                  Launching soon, Casa Grab promises an exclusive property listing service that blends cutting-edge AI with a personalized
                  touch, ensuring that your property journey is nothing short of extraordinary.
                </p>
                <p className="paragraph-light">
                  Be a pioneer in this transformative chapter of South African real estate. By signing up now, you secure your place
                  among the first to witness the unveiling of Casa Grab's unique features, insights, and early listing opportunities.
                  Whether you're a property owner eager to showcase your asset, a homebuyer in search of your dream residence, or an
                  investor seeking lucrative opportunities, Casa Grab is your key to unlocking the full potential of the South African
                  property market. Don't miss out – sign up today and be part of a groundbreaking era in property exploration!
                </p>
              </div>

              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSceNqY996GjhsCd6WNfeNBmzXLXvWNUra6e2UPthC77OGJ_FQ/viewform?usp=sf_link"
                target="_blank" className="button cc-jumbo-button cc-jumbo-white w-inline-block">
                mailing list
              </a>
            </div><img src="./src_assets/5703695.png"
              alt="Made in South Africa"
              id="w-node-_86e64837-0616-515b-4568-76c147234d4c-5c1e9243"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, 922px" />
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="footer-wrap">
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
