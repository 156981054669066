import { FullProperty } from "../../models/fullProperty";
import { SearchListingsQuery } from "../../models/search";
import raw from "./properties.json"

const itemsPerPage = 20;

const min = (a: number, b: number) => {
    if (a > b) {
        return b;
    }
    return a;
}

const getAllProperties = (query: SearchListingsQuery) => {
    var transformed_list = [];
    for (let index = query.currentPage * itemsPerPage; index < min(query.currentPage * itemsPerPage + itemsPerPage, raw.length); index++) {
        const element = raw[index];
        transformed_list.push(FullProperty.fromDict(new Map(Object.entries(element))))
    }
    return transformed_list;
}

const getNumberOfProperties = () => {
    return raw.length;
}

const getById = (id: String) => {
    for (let index = 0; index < raw.length; index++) {
        const element = raw[index];
        var property = FullProperty.fromDict(new Map(Object.entries(element)));
        if (property.id === id) {
            return property;
        }
    }
    return null;
}

const SearchService = {
    getAllProperties: getAllProperties,
    getById: getById,
    getNumberOfProperties: getNumberOfProperties
}

export { SearchService };