import { FullProperty } from "../../models/fullProperty";
import { PropertyType } from "../../models/propertyType";
import { TransactionType } from "../../models/transactionType";
import raw from "./strings.json"

const getStringById = (string_id: string) => {
    var strings = new Map(Object.entries(raw));
    var response = strings.get(string_id);
    if (response == undefined) {
        console.log("missing string id: " + string_id)
        return "";
    }
    return response;
}

function transactionTypeAsString(listing: FullProperty) {
    if (listing.transactionType === TransactionType.Rent) {
        return "rent"
    }
    if (listing.transactionType === TransactionType.Buy) {
        return "sale"
    }
    return "rent and sale"
}


function propertyTypeAsString(listing: FullProperty) {
    if (listing.propertyType === PropertyType.House) {
        return "house"
    }
    if (listing.propertyType === PropertyType.Flat) {
        return "flat / appartment"
    }
    if (listing.propertyType === PropertyType.VacantLand) {
        return "land"
    }
    return "property"
}

function boolToYesOrNo(b: boolean) {
    if (b) {
        return "Yes"
    }
    return "No"
}

const StringsService = {
    getStringById: getStringById,
    transactionTypeAsString: transactionTypeAsString,
    propertyTypeAsString: propertyTypeAsString,
    boolToYesOrNo: boolToYesOrNo
}

export { StringsService };