class Area {
    provinceId: String = ""
    areadId: String = ""
    suburbId: String = ""


    public static fromDict(dict: Map<String, String>) {
        var area: Area = new Area();
        area.provinceId = String(dict.get("provinceId")) ?? area.provinceId;
        area.areadId = String(dict.get("areadId")) ?? area.areadId;
        area.suburbId = String(dict.get("suburbId")) ?? area.suburbId;
        return area
    }

    public asDict() {
        return {
            "provinceId": this.provinceId,
            "areadId": this.areadId,
            "suburbId": this.suburbId
        }
    }
}

export { Area };