import React, { useState } from 'react';
import Card from '@mui/material/Card';
import { CardMedia, Typography } from '@mui/material';
import { StringsService } from '../../services/strings/strings';


interface IProps {
    id: string | String
}


function DescriptionCard(props: IProps) {
    return (
        <Card sx={{ backgroundColor: "#F0FFFF", justifyContent: "center", display: "flex !important", flexDirection: "column" }}>
            <div className="p8">
                <Typography variant="h5">
                    {StringsService.getStringById(props.id + "-description-header")}
                </Typography>
            </div>
            <div className="p8">
                <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                    {StringsService.getStringById(props.id + "-long-description")}
                </Typography>
            </div>
        </Card>
    );
}

export default DescriptionCard;
