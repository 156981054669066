import React, { useState } from "react";
import {
  useLocation
} from "react-router-dom";
import { SearchListingsQuery } from "../models/search"
import { SearchService } from "../services/search/search"
import { ListingCard } from "./listingCard"
import "./index.css"
import { Header } from "../header";
import { Typography } from "@mui/material";
import Paginate, { ReactPaginateProps } from 'react-paginate'
import classnames from 'classnames'
import styles from './pagination.module.css'
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ListingSearchPage() {

  ReactGA.send({ hitType: "pageview", page: "/search", title: "Casa Grab" });

  let query = useQuery();
  var searchQuery = SearchListingsQuery.transformFromQueryParams(query);
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 20;
  searchQuery.currentPage = pageNumber;
  const pageCount = Math.ceil(SearchService.getNumberOfProperties() / itemsPerPage);
  console.log(pageCount)

  var listings = SearchService.getAllProperties(searchQuery);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newPageNumber = event.selected;
    console.log(
      `User requested page number ${newPageNumber}`
    );
    console.log(listings)
    setPageNumber(newPageNumber);
  };

  return (
    <div>

      <Helmet>
        <title>Casa Grab - Search</title>
        <meta name="description" content="Casa Grab: Search All properties that we have" />
        {/* <!-- Open Graph Tags --> */}
        <meta property="og:title" content="Casa Grab - Real Estate Listings" />
        <meta property="og:description" content="Your platform to easily showcase your property listings in South Africa for free!" />
        <meta property="og:image" content="./src_assets/659bf227c489a49f48e619d0_Test.png" />

        {/* <!-- Twitter Card Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Casa Grab - Real Estate Listings" />
        <meta name="twitter:description" content="Your platform to easily showcase your property listings in South Africa for free.!" />
        <meta name="twitter:image" content="./src_assets/659bf227c489a49f48e619d0_Test.png" />

      </Helmet>
      <Header />
      <div className="p16 bgrTeal tcW">
        <Typography variant="h4" component="div" sx={{ color: 'white' }}>
          Houses for sale in South Africa
        </Typography>
        <Typography variant="body1" component="div" sx={{ color: 'white' }}>
          Properties listed below are all across South Africa, <br />
          Search coming soon...
        </Typography>
      </div>
      <div className="ListingSearchPage">
        <div className="w20p" />
        <div className="ListingCardColumn">
          {
            listings.map(l => <ListingCard listing={l} key={"listing-search-card-" + l.id} />)
          }
        </div>
        <div className="w20p" />
      </div>

      <div className={classnames('issuesPagination', styles.pagination)}>
        <Paginate
          onPageChange={handlePageClick}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={20}
          forcePage={pageNumber}
          nextLabel="&rarr;"
          previousLabel="&larr;"
        />
      </div>
      <div className="section">
        <div className="container">
          <div className="footer-wrap">
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingSearchPage;
