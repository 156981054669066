class Location { 
    latitude: number = 0;
    longitude: number = 0;

    public static fromDict(dict: Map<String, number>) {
        var location: Location = new Location();
        location.latitude = dict.get("latitude") ?? location.latitude;
        location.longitude = dict.get("longitude") ?? location.latitude;
        return location
    }

    public asDict() {
        return {
            "latitude": this.latitude,
            "longitude": this.longitude
        }
    }
}

export { Location };