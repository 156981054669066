import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { FullProperty } from '../../models/fullProperty';
import "./index.css"
import { StringsService } from '../../services/strings/strings';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import KingBedIcon from '@mui/icons-material/KingBed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { useScreenSize } from '../../hooks/useScreenSize';
import { AreaService } from '../../services/area/areaService';

interface IProps {
    listing: FullProperty
}

function DesktopCard({ listing }: IProps) {
    var screenSize = useScreenSize()
    var cardImageWidth = 300;
    if (screenSize.width > 1000) {
        cardImageWidth += 0.2 * (screenSize.width - 1000);
    }
    return (
        <div className='ListingCardBase'>
            <CardActionArea >
                <Card sx={{ display: 'flex', width: "100%" }} >
                    <CardMedia
                        component="img"
                        sx={{ width: cardImageWidth }}
                        image={StringsService.getStringById(listing.id + "-thumbnail")}
                        alt="Live from space album cover"
                    />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', width: "100%", height: "100%" }}>
                        <div className="flex-row jcsb w100p">
                            <div className="flex-column">
                                <Typography sx={{ display: "inline" }} variant="h5" component="div">
                                    {StringsService.getStringById(listing.id + "-property-price-zar-displayable")}
                                </Typography>
                                <Typography variant="subtitle1" component="div">
                                    {StringsService.getStringById(listing.id + "-short-property-summary")}
                                </Typography>
                                <Typography variant="subtitle2" component="div">
                                    {AreaService.getNameByArea(listing.area)}
                                </Typography>
                            </div>

                            <CardMedia
                                component="img"
                                sx={{ width: 151 }}
                                image={StringsService.getStringById("agency-" + listing.agencyId + "-logo")}
                                alt="Live from space album cover"
                            />
                        </div>
                        <Typography gutterBottom variant="body1" component="div">
                            {StringsService.getStringById(listing.id + "-short-summary")}
                        </Typography>
                        <div className='h100p' />
                        <div className="flex-row ">
                            <div className='pr-16'>
                                <KingBedIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.bedroomCount}
                            </div>
                            <div className='pr-16'>
                                <BathtubIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.bathroomCount}
                            </div>
                            <div className='pr-16'>
                                <DirectionsCarIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.carCount}
                            </div>
                            <div className='pr-16'>
                                <SquareFootIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.houseSize}
                                {" "}
                                {StringsService.getStringById("meters_squared")}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </CardActionArea>
        </div>
    );
}

function MobileCard({ listing }: IProps) {
    return (
        <div className='ListingCardBase'>
            <CardActionArea>
                <Card sx={{ display: 'flex', width: "100%", flexDirection: 'column' }}>

                    <CardMedia
                        component="img"
                        sx={{ height: 200 }}
                        image={StringsService.getStringById(listing.id + "-thumbnail")}
                        alt="Live from space album cover"
                    />
                    <CardContent>
                        <div className="flex-row jcsb">
                            <div className="flex-column">
                                <Typography variant="h5" sx={{ textDecoration: 'inline' }} component="div">
                                    {StringsService.getStringById(listing.id + "-property-price-zar-displayable")}
                                </Typography>
                                <Typography variant="subtitle1" component="div">
                                    {StringsService.getStringById(listing.id + "-short-property-summary")}
                                </Typography>
                                <Typography variant="subtitle2" component="div">
                                    {AreaService.getNameByArea(listing.area)}
                                </Typography>
                            </div>

                            <CardMedia
                                component="img"
                                sx={{ width: 151 }}
                                image={StringsService.getStringById("agency-" + listing.agencyId + "-logo")}
                                alt="Live from space album cover"
                            />
                        </div>
                        <Typography gutterBottom variant="body1" component="div">
                            {StringsService.getStringById(listing.id + "-short-summary")}
                        </Typography>
                        <div className="flex-row ">
                            <div className='pr-16'>
                                <KingBedIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.bedroomCount}
                            </div>
                            <div className='pr-16'>
                                <BathtubIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.bathroomCount}
                            </div>
                            <div className='pr-16'>
                                <DirectionsCarIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.carCount}
                            </div>
                            <div className='pr-16'>
                                <SquareFootIcon />
                            </div>
                            <div className='pr-16'>
                                {listing.houseSize}
                                {" "}
                                {StringsService.getStringById("meters_squared")}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </CardActionArea>
        </div>
    );
}

function ListingCard({ listing }: IProps) {
    var screenSize = useScreenSize()
    return <a href={"/listing/" + listing.id}>
        {
            screenSize.width > 800 ?
                < DesktopCard listing={listing} />
                :
                <MobileCard listing={listing} />
        }
    </a>
}

export { ListingCard };