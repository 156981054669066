import { Area } from "./area";
import { Location } from "./location";
import { PropertyType, propertyTypeFromString } from "./propertyType";
import { TransactionType, transactionTypeFromString } from "./transactionType";

class FullProperty {
    id: String = "";
    name: String = "";
    price: String = "";
    location: Location = new Location();
    agencyId: String = "";
    agentId: String = "";
    isActive: boolean = false;
    area: Area = new Area()
    houseSize: number = 0
    plotSize: number = 0
    bedroomCount: number = 0;
    bathroomCount: number = 0;
    carCount: number = 0;
    transactionType: TransactionType = TransactionType.Both;
    propertyType: PropertyType = PropertyType.Any;
    isNewBuild: boolean = false;
    hasPool: boolean = false;
    isPetFriendly: boolean = false;
    hasGarden: boolean = false;
    hasFlatlet: boolean = false;
    isSecurityEstate: boolean = false;
    isRetiermentVillage: boolean = false;
    isOnAuction: boolean = false;
    
    public static fromDict(dict: Map<string, any>) {
        // if any values dont exist in dict, then keep default values defined above.
        var property: FullProperty = new FullProperty();
        property.id = dict.get("id") ?? property.id
        property.price = dict.get("price") ?? property.price;
        property.location = Location.fromDict(new Map(Object.entries(dict.get("location") ?? {})));
        property.agencyId = dict.get("agencyId") ?? property.agencyId;
        property.agentId = dict.get("agentId") ?? property.agentId;
        property.isActive = dict.get("isActive") ?? property.isActive;
        property.area = Area.fromDict(new Map(Object.entries(dict.get("area") ?? {})));
        property.houseSize = dict.get("houseSize") != "" ? dict.get("houseSize") : 0;
        property.plotSize = dict.get("plotSize") ?? property.plotSize;
        property.bedroomCount = dict.get("bedroomCount") != "" ? dict.get("bedroomCount") : 0;
        property.bathroomCount = dict.get("bathroomCount") != "" ? dict.get("bathroomCount") : 0;
        property.carCount = dict.get("carCount") != "" ? dict.get("carCount") : 0;
        if (property.carCount === 0) {
            property.carCount = dict.get("garageSize") != "" ? dict.get("garageSize") : 0;
        }
        property.transactionType = transactionTypeFromString(dict.get("transactionType") ?? "");
        property.propertyType = propertyTypeFromString(dict.get("propertyType") ?? "");
        property.isNewBuild = dict.get("isNewBuild") ?? property.isNewBuild;
        property.hasPool = dict.get("hasPool") ?? property.hasPool;
        property.isPetFriendly = dict.get("isPetFriendly") ?? property.isPetFriendly;
        property.hasGarden = dict.get("hasGarden") ?? property.hasGarden;
        property.hasFlatlet = dict.get("hasFlatlet") ?? property.hasFlatlet;
        property.isSecurityEstate = dict.get("isSecurityEstate") ?? property.isSecurityEstate;
        property.isRetiermentVillage = dict.get("isRetiermentVillage") ?? property.isRetiermentVillage;
        property.isOnAuction = dict.get("isOnAuction") ?? property.isOnAuction;
        return property;
    }

    public asDict() {
        return {
            "id": this.id,
            "price": this.price,
            "location": this.location.asDict(),
            "agencyId": this.agencyId,
            "agentId": this.agentId,
            "isActive": this.isActive,
            "area": this.area,
            "houseSize": this.houseSize,
            "plotSize": this.plotSize,
            "bedroomCount": this.bedroomCount,
            "bathroomCount": this.bathroomCount,
            "carCount": this.carCount,
            "transactionType": this.transactionType,
            "propertyType": this.propertyType,
            "isNewBuild": this.isNewBuild,
            "hasPool": this.hasPool,
            "isPetFriendly": this.isPetFriendly,
            "hasGarden": this.hasGarden,
            "hasFlatlet": this.hasFlatlet,
            "isSecurityEstate": this.isSecurityEstate,
            "isRetiermentVillage": this.isRetiermentVillage,
            "isOnAuction": this.isOnAuction,
        }
    }
}

export { FullProperty };