import raw from "./images.json"

const getGalleryImages = (propertyId: string) => {
    var images = new Map(Object.entries(raw));
    var response = images.get(propertyId);
    if (response == undefined) {
        console.log("missing images for property id: " + propertyId)
        return [];
    }
    return response;
}

const ImageService = {
    getGalleryImages: getGalleryImages
}

export { ImageService };