import React, { useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { StringsService } from '../../services/strings/strings';
import { FullProperty } from '../../models/fullProperty';


interface IProps {
    listing: FullProperty
}

function OverviewCard(props: IProps) {
    const rows = [
        ["Listing Number", props.listing.id],
        ["Type of Property", StringsService.propertyTypeAsString(props.listing)],
        ["Available for ", StringsService.transactionTypeAsString(props.listing)],
        ["Price", StringsService.getStringById(props.listing.id + "-property-price-zar-displayable")],
        ["Erf Size", props.listing.plotSize + " " + StringsService.getStringById("meters_squared")],
        ["Floor Size", props.listing.houseSize + " " + StringsService.getStringById("meters_squared")],
        ["Has a flatlet", StringsService.boolToYesOrNo(props.listing.hasFlatlet)],
        ["Has a garden", StringsService.boolToYesOrNo(props.listing.hasGarden)],
        ["Has a pool", StringsService.boolToYesOrNo(props.listing.hasPool)],
        ["Is a new build", StringsService.boolToYesOrNo(props.listing.isNewBuild)],
        ["Is on auction", StringsService.boolToYesOrNo(props.listing.isOnAuction)],
        ["Is pet friendly", StringsService.boolToYesOrNo(props.listing.isPetFriendly)],
        ["For retierees", StringsService.boolToYesOrNo(props.listing.isRetiermentVillage)]
    ];

    return (<TableContainer component={Paper}>
        <Table aria-label="simple table" sx={{backgroundColor: "#F0FFFF"}}>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row[0] as string}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} 
              >
                <TableCell component="th" scope="row">
                  {row[0]}
                </TableCell>
                <TableCell align="left">{row[1]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
}

export default OverviewCard;
